import { URL_ADMIN_COMPANY } from "./navigation";
import { validateDomains, isInvalidTextField, isValidPhoneNumber } from "./validation"
import validator from "validator"

/**
 * Maximum length of a company abbreviation
 */
export const COMPANY_ABBREVIATION_MAX_LENGTH = 18;

export const COMPANY_ROLE_NAME_TO_ROLE_ID = Object.freeze({
    'Public non-ISA': 1,
    'ISA': 2,
    'CDM': 3,
    'Provider': 4
});

export const getCompanyDetailLink = (queryParams) => {
    return `${URL_ADMIN_COMPANY}?${queryParams.join('&')}`;
};

export const validateCompany = (input, allowedScopes) => {
    let errors = {}

    errors.name = isInvalidTextField(input?.name, 1, 100, ' ,.-+@')
    errors.abbreviation = isInvalidTextField(input?.abbreviation, 1,
        COMPANY_ABBREVIATION_MAX_LENGTH, ' ,.-/')
    errors.address = isInvalidTextField(input?.address, 1, 500, ' ,.-/')
    errors.poc_name = isInvalidTextField(input?.poc_name, 1, 100, " .,-'")
    errors.cognito_group_name = isInvalidTextField(input?.cognito_group_name,
        1, 128, '-_')
    errors.ivt = isInvalidTextField(input?.ivt, 1, 128, '-_')

    if (!validator.isURL(input?.url)) {
        errors.url = 'Invalid URL format'
    }

    if (input?.logo_url && !validator.isEmpty(input?.logo_url) &&
        !validator.isURL(input?.logo_url)) {
        errors.logo_url = 'Invalid URL format'
    }

    if (!isValidPhoneNumber(input?.poc_phone)) {
        errors.poc_phone = 'Invalid phone number format'
    }

    if (!validator.isEmail(input?.poc_email)) {
        errors.poc_email = 'Invalid email'
    }

    if (input?.client_id && !validator.isEmpty(input?.client_id)) {
        errors.client_id = isInvalidTextField(input?.client_id, 0, 128)
    }

    if (input?.client_secret && !validator.isEmpty(input?.client_secret)) {
        errors.client_secret = isInvalidTextField(input?.client_secret, 0, 128)
    }

    if (input?.api_key && !validator.isEmpty(input?.api_key)) {
        errors.api_key = isInvalidTextField(input?.api_key, 0, 128)
    }

    // email_domain is a comma-separated list of email domains
    if (input?.email_domain && !validator.isEmpty(input.email_domain)) {
        errors.email_domain = validateDomains(input.email_domain.split(','));
    }

    if (input?.isa_metadata && !validator.isEmpty(input?.isa_metadata)) {
        errors.isa_metadata = isInvalidTextField(input?.isa_metadata, 0, 500, ' .-_()/')
    }

    if (input?.roles && Object.values(input.roles).filter(v => v).length === 0) {
        errors.companyRoles = 'Invalid role selection'
    }

    if (allowedScopes &&
        Object.values(allowedScopes).filter(v => v).length <= 0) {

        errors.allowedScopes = 'Invalid scope selection'
    }

    return errors
}

// Convert the access detail data to the company detail data
export const convertAccessRequestToCompanyDetail = (accessRequest) => {
    let rtn = {};

    if (accessRequest) {
        rtn = {
            name: accessRequest.team_name,
            address: accessRequest.organization_address,
            url: accessRequest.team_url,
            logo_url: accessRequest.team_logo_url,
            poc_name: accessRequest.team_poc_name,
            poc_phone: accessRequest.team_poc_phone,
            poc_email: accessRequest.team_poc_email,
            email_domain: accessRequest.requester_email ? accessRequest.requester_email.split('@')[1] : null,
        };
    }

    return rtn;
}